import * as utils       from './utils.js'
import mobile           from './mobile.js'
import { gsap, Power2 } from 'gsap/index.js'
import { CustomEase }   from 'gsap/CustomEase.js'
import * as navigation  from './navigation.js'
import * as lazyload    from './lazyload.js'
import * as content     from './content.js'
import * as pages       from './../pages/index.js'
import * as dev         from './../components/dev.js'

gsap.registerPlugin( Power2, CustomEase )

export let build = async () => {

    if ( !content.all.loaded ) {

        // ADD resize handlers, and call right away
        window.addEventListener( 'resize', resize_handler )
        resize_handler()

        // TEST for mobile via user agent string
        state.is_mobile = mobile()
        if ( state.is_mobile ) {

            utils.qs( 'body' ).body.classList.add( 'mobile' )

        }

        console.log(
            '%c state ', 'background: #FF1665; color: #fff',
            state,
        )
        
        // FETCH dynamic content
        {
            // Content has not been loaded yet. Go get it!
            
            // let response = await fetch( '/assets/content.json' )
            // let data = await response.json()
            // content.store( data )
        }
    
        // STATIC content ships with built app
        {
            content.build()
        }
        
        // Build app
        pages.build()
        dev.build()
        navigation.build()
        lazyload.build()

        // Load the app at our entry point
        navigation.locate()

    }

}

let resize_handler = () => {

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty( '--vh', `${vh}px` )

    sync_window_size_state()

    // ADD any more page resize handlers here

}

export let sync_window_size_state = () => {

    let w = window.innerWidth
    let h = window.innerHeight

    state.window_size = {
        w: w,
        h: h,
        aspect: w / h,
        d: Math.sqrt( Math.pow( w, 2 ) + Math.pow( h, 2 ) ),
        px: window.devicePixelRatio,
        u_px_norm: { x: w, y: h },
        aspect_vec: { x: 1.0, y: w / h },
        x: 0,
        y: 0,
        u: 1.0 / w,
        v: 1.0 / h,
    }
	
}

export let reset_scroll = () => {

    utils.qs( '.container' ).scrollTop = 0
    document.documentElement.scrollTop = 0
    window.scrollTo( 0, 0 )

}

// SIMPLE state object for tracking certain states across the app

export let state = {

    is_mobile: false,
	
    window_size: {},

    session_id: utils.uuid(),

}