import Page     from './../core/Page.js'
import template from './../../templates/pages/error.dot'

export let path = 'error'

const errors = {
    'default': {
        'header': 'Unknown error',
        'body': 'Hrmm. How did we get here?',
    },
    '404': {
        'header': '404 - Page not found',
        'body': 'Sorry about that. Try a different URL.',
    },
    '500': {
        'header': '500 - Server error',
        'body': 'Sorry about that. Try again later?',
    },
}

export let create = () => {

    let p = new Page( {
        path: 'error',
        is_instances: false,
        template: template,
    } )

    p.fetch = async function ( query ) {
        return new Promise( ( resolve ) => {

            if ( errors[ query ] ) {
                this.data = errors[ query ]
            }
            else {
                this.data = errors.default
            }

            resolve()
        } )
    }

    return p

}
