{
    "seo": {
        "home": {
            "title": "{{{ NAME }}}",
            "meta_name": {
                "keywords": "{{{ KEYWORDS }}}",
                "description": "{{{ DESCRIPTION }}}"
            },
            "meta_property": {
                "og:type": "website",
                "og:url": "{{{ URL }}}",
                "og:image": "{{{ URL }}}/assets/images/og.jpg",
                "og:image:width": "1200",
                "og:image:height": "630",
                "og:title": "{{{ NAME }}}",
                "og:description": "{{{ DESCRIPTION }}}",
                "og:site_name": "{{{ SITENAME }}}"
            }
        }
    },
    "home": "{{{ NAME }}}",
    "about": "about hello world",
    "gallery": {
        "a": [
            {
                "uri": "/assets/images/pic-1.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "/assets/images/pic-2.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "https://player.vimeo.com/video/393795643",
                "width": 1920,
                "height": 1080
            },
            {
                "uri": "/assets/images/video-1.mp4",
                "width": 1920,
                "height": 1080
            },
            {
                "uri": "/assets/images/pic-3.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "https://player.vimeo.com/video/393795643",
                "width": 1920,
                "height": 1080
            },
            {
                "uri": "/assets/images/pic-4.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "/assets/images/pic-5.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "/assets/images/video-2.mp4",
                "width": 1920,
                "height": 1080
            },
            {
                "uri": "/assets/images/pic-6.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "/assets/images/pic-7.jpg",
                "width": 1200,
                "height": 900
            },
            {
                "uri": "/assets/images/pic-8.jpg",
                "width": 1200,
                "height": 900
            }
        ],
        "b": [
            {
                "uri": "https://player.vimeo.com/video/393795643",
                "width": 1920,
                "height": 1080
            }
        ],
        "c": [
            {
                "uri": "/assets/images/pic-8.jpg",
                "width": 1200,
                "height": 900
            }
        ]
    }
}