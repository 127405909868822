import * as home    from './home.js'
import * as about   from './about.js'
import * as gallery from './gallery.js'
import * as error   from './error.js'

export let all = {}

export let build = () => {
    
    register( home )
    register( about )
    register( gallery )
    register( error )
    //
    // ADD new pages here
    // 
    
    console.log( '%c pages ', 'background: #FFD086; color: #222', all )
    
}

export let register = ( p ) => {
    let slug = p.path.replaceAll( '/', '.' )
    all[ slug ] = p.create
}

export let get = ( path ) => {

    if ( all[ path ] !== undefined ) {
        return all[ path ]
    }
    else {
        return false
    }

}