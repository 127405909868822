<div class="wrap">

    <div class="header"> Home header here </div>

    <div class="body"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis
        aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </div>

    <div class="buttons-row">
        <a class="button" href="/gallery/a" data-link="internal"> Go to Gallery A </a>
        <a class="button" href="/gallery/b" data-link="internal"> Go to Gallery B </a>
        <a class="button" href="/gallery/c" data-link="internal"> Go to Gallery C </a>
        <a class="button" href="/about" data-link="internal"> Go to About </a>
    </div>

</div>