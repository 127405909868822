import Page     from './../core/Page.js'
import template from './../../templates/pages/home.dot'

export let path = 'home'

export let create = () => {

    let p = new Page( {
        path: 'home',
        is_instances: false,
        template: template,
        nodes_list: {
            qs: {
                'header': '.header',
                'body': '.body',
            },
            qsa: {
                'buttons': '.button',
            },
        },
    } )
    
    return p

}
