<div class="wrap">

    <div class="header"> Gallery header here </div>

    <div class="body">

        {{~ it:media }}

        <div class="media-container">

            <!-- Vimeo Embed -->
            {{? media.uri.includes("vimeo.com") }}
            {{#def.embed_vimeo:media }}

            <!-- Youtube embed -->
            {{?? media.uri.includes("youtube.com")}}
            <iframe blazy-src="{{= media.uri }}" title="youtube embed"></iframe>

            <!-- Plain video element -->
            {{?? media.uri.includes('.mp4') }}
            <video muted loop playsinline height="{{= media.height }}" width="{{= media.width }}" alt="hbo taser"
                style="aspect-ratio: {{= media.width/media.height}};" blazy-src="{{= media.uri }}">
                <source type="video/mp4" />
            </video>

            <!-- Image -->
            {{?? Math.random() > 0.5 }}
            <img blazy-src="{{= media.uri }}" width="{{= media.width }}" height="{{= media.height }}" alt="frog-1"
                style="aspect-ratio: {{= media.width/media.height}};">

            <!-- CSS Background image -->
            {{?? }}
            <div blazy-src="{{= media.uri }}" width="{{= media.width }}" height="{{= media.height }}"
                style="aspect-ratio: {{= media.width/media.height}};"></div>

            {{?}}

        </div>
        {{~ }}

    </div>

    <div class="svg">
        {{#def.loadfile("./src/assets/svgs/test.svg") }}
    </div>

    <div class="buttons-row">
        <a class="button" href="/home" data-link="internal"> Go Home </a>
        <a class="button" href="/gallery/a" data-link="internal"> Go to Gallery A </a>
        <a class="button" href="/gallery/b" data-link="internal"> Go to Gallery B </a>
        <a class="button" href="/gallery/c" data-link="internal"> Go to Gallery C </a>
    </div>

</div>