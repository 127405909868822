import Page     from './../core/Page.js'
import template from './../../templates/pages/about.dot'

export let path = 'about'

export let create = () => {

    let p = new Page( {
        path: 'about',
        is_instances: false,
        template: template,
    } )

    return p

}