import static_data from './../../assets/content.json'

export let all = {
    loaded: false,
}

// - - - - - - -  - - - - - - - - - - - - - - - - - - ORGANIZE incoming content

export let build = () => {

    all = static_data
    finish_store()

}

export let store = ( data, callback ) => {

    all = data

    finish_store()

    if ( callback ) callback()

}

let finish_store = () => {

    all.loaded = true

    console.log( 
        '%c content ', 'background: #C476E7; color: #fff', 
        all,
    )

}