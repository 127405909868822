import BlazyLoader from 'blazyloader'

export let blazy

export let update = () => {

    if ( !blazy.ready ) {
        return
    }
    
    blazy.sync_nodes()
    
}

export let build = () => {

    blazy = new BlazyLoader( {
        tag: 'blazy-src',
        view_scale: 2.0,
        unload: true,
        container: document.querySelector( '.container' ),
    } )

    /*
    blazy.on( 'blazy:set', ( node ) => {
        console.log( 'loading', node.getAttribute( 'blazy-src' ) )
    } )
    
    blazy.on( 'blazy:loaded', ( node ) => {
        console.log( 'loaded', node.getAttribute( 'blazy-src' ) )
    } )
    
    blazy.on( 'blazy:unset', ( node ) => {
        console.log( 'unload', node.getAttribute( 'blazy-src' ) )
    })
    */
    
}