<div class="wrap">

    <div class="header"> About header here </div>

    <div class="body"> Ut atque praesentium aut assumenda cupiditate eos veniam internos. Eum eaque nihil ut dolor
        laudantium est voluptatem
        dolorem in molestiae ducimus vel iure sint. Quo eligendi voluptatem et magnam nihil non fugiat repudiandae et
        vitae
        dolores et quia recusandae qui quia consequatur. Qui ipsam nisi et autem consequatur cum autem sint est iure
        repudiandae
        et enim consequatur est nobis totam. </div>

    <div class="buttons-row">
        <a class="button" href="/home" data-link="internal"> Go Home </a>
    </div>

</div>