import Page         from './../core/Page.js'
import template     from './../../templates/pages/gallery.dot'
import * as content from './../core/content.js'

export let path = 'gallery'

export let create = () => {

    let p = new Page( {
        path: path,
        is_instances: true,
        template: template,
    } )
    
    p.fetch = async function ( query ) {
        return new Promise( ( resolve ) => {

            if ( content.all.gallery[ query ] ) {
                this.data = content.all.gallery[ query ]
            }
            else {
                this.data = {
                    body: 'Not found',
                }
            }

            this.fetched = true

            resolve()

        } )
    }

    return p

}