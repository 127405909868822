import * as content     from './content.js'
import { gsap, Power2 } from 'gsap/index.js'

export default class Page {

    constructor( data ) {

        // BUILT IN
        this.loaded = false
        this.el = null
        this.nodes = {}
        this.nodes_list = []

        // REQUIRED
        this.path = data.path
        this.is_instance = data.is_instance
        this.template = data.template

        // OVERRIDES
        if ( data.on_build ) this.on_build = data.on_build
        if ( data.fetch ) this.fetch = data.fetch
        if ( data.tween_in ) this.tween_in = data.tween_in
        if ( data.tween_out ) this.tween_out = data.tween_out
        if ( data.resize ) this.resize = data.resize
        if ( data.nodes_list ) this.nodes_list = data.nodes_list

    }

    // DEFAULT fetch
    async fetch( /*query*/ ) {

        return new Promise( ( resolve ) => {
            
            this.data = content.all

            this.fetched = true

            resolve()

        } )
        
    }

    on_build() {
        // nothing
    }

    build( virtual_document ) {

        // Static site generator will pass in the virtual dom
        let _document = ( virtual_document )
            ? virtual_document
            : document

        let el = _document.createElement( 'div' )
        el.classList.add( 'container-inner' )
        el.classList.add( 'hidden' )
        let page_div = _document.createElement( 'div' )
        page_div.classList.add( 'page' )
        page_div.classList.add( this.path.replaceAll( '/', '' ) )
        page_div.innerHTML = this.template( this.data )
        el.appendChild( page_div )
        
        this.nodes.container = el
        this.nodes.page_div = page_div

        return el
    }

    get_nodes() {

        for ( let n in this.nodes_list.qs ) {
            this.nodes[ n ] = this.qs( this.nodes_list.qs[ n ] )
        }

        for ( let n in this.nodes_list.qsa ) {
            this.nodes[ n ] = this.qsa( this.nodes_list.qsa[ n ] )
        }

        console.log( `%c nodes | ${ this.path } `, 'background: #F9704B; color: #fff', this.nodes )
    }

    qs( selector ) {
        return this.nodes.page_div.querySelector( selector )
    }

    qsa( selector ) {
        return Array.prototype.slice.call( this.nodes.page_div.querySelectorAll( selector ) )
    }

    async tween_in( other, reverse ) {
        return new Promise( ( resolve ) => {
            
            let dir = reverse ? -1 : 1

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                    this.nodes.container.classList.remove( 'hidden' )
                },
                onComplete: () => {
                    resolve()

                    tl.kill()
                    tl = null
                },
            } )
            
            tl.add( gsap.fromTo(
                this.nodes.page_div,
                { opacity: 0, y: 10 },
                { opacity: 1, y: 0, ease: Power2.easeOut, duration: 0.25 },
            ) )
            
            tl.play()

        } )
    }

    async tween_out ( other, reverse ) {
        return new Promise( ( resolve ) => {

            let dir = reverse ? -1 : 1

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                },
                onComplete: () => {
                    this.nodes.container.classList.add( 'hidden' )

                    resolve()

                    tl.kill()
                    tl = null
                },
            } )

            tl.add( gsap.to(
                this.nodes.page_div,
                { opacity: 0, y: -10, ease: Power2.easeIn, duration: 0.25 },
            ) )

            tl.play()
        } )
    }

    destroy() {
        this.nodes.container.remove()
    }

    resize() {

    }

}