import * as controller from './core/controller.js'

const main = async () => {

    if ( document.querySelector( 'body' ).classList.contains( 'static' ) ) {
        document.querySelector( 'body' ).classList.remove( 'static' )
    }

    if ( process.env.NODE_ENV === 'production' ) {
        console.log = function () {}
    }

    if ( navigator.serviceWorker ) {
        navigator.serviceWorker.register(
            new URL( './../worker.js', import.meta.url ),
            { type: 'module' },
        ).catch( e => {
            console.error( 'ServiceWorker registration failed: ', e )
        } )
    }

    console.log(
        '%c env ', 'background: #4A48D4; color: #fff',
        {
            env: process.env.NODE_ENV,
            url: process.env.URL,
        },
    )

    controller.build()
    
}

document.addEventListener( 'DOMContentLoaded', main )