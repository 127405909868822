<div class="wrap">

    <div class="header"> {{= it.header }} </div>

    <div class="body"> {{= it.body }} </div>

    <div class="buttons-row">
        <a class="button" href="/home" data-link="internal"> Go Home </a>
    </div>

</div>