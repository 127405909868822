import * as pages      from './../pages/index.js'
import * as lazyload   from './lazyload.js'
import * as controller from './controller.js'

export let status = {
    current: null,
    previous: null,
    uid: 0,
    reverse: false,
    pages: {
        current: null,
    },
    transitioning: false,
}

export let build = function() {

    // Add Back/Forward button listener
    window.addEventListener( 'popstate', function ( e ) {

        if ( status.transitioning ) {
            e.preventDefault()
            return
        }

        // Parse URL request, set base to home
        let _url = window.location.pathname

        _url = ( _url === null || _url === '/' )
            ? 'home'
            : _url.replaceAll( /^.*\/\/[^\/]+/g, '' )

        if ( _url.startsWith( '/' ) ) {
            _url = _url.slice( 1 )
        }
        if ( _url.endsWith( '/' ) ) {
            _url = _url.slice( 0, -1 )
        }

        // Test if reversing
        if ( window.history.state !== null ) {
            status.reverse = ( window.history.state.uid < status.uid )
                ? true
                : false
            status.uid = window.history.state.uid
        }

        status.previous = status.current
        status.current = _url

        // Pass along to router
        build_new_page( _url )
    } )

}

// PARSE url on page load and determine where we are, normalizing things
export let locate = () => {

    // Get window location and strip file extension and leading slash
    let path = window.location.pathname
    path = path.replace( path.match( /\.[0-9a-z]+$/i ), '' )

    //Trim leading and trailing slashes
    if ( path[ 0 ] === '/' )
        path = path.slice( 1, path.length )
    if ( path[ path.length - 1 ] === '/' )
        path = path.slice( 0, path.length - 1 )
    
    // Root url is home
    path = ( path === '' ) ? 'home' : path

    // Filter instance queries
    let template_str = path.replaceAll( '/', '.' )
    if ( pages.get( template_str ) ) {
        let state = { path: path, uid: window.history.length }
        window.history.replaceState(
            state,
            null,
            window.location.origin + '/' + path + '/',
        )
    }
    else {

        // Prep url components for testing
        let url_parts = path.split( '/' )

        // Format path with periods instead of slashes
        let normal_path = path.replaceAll( '/', '.' )

        // String after final / is instance id, the rest is the path
        let query = url_parts[ url_parts.length - 1 ]
        let instanced_path = normal_path.replace( `.${query}`, '' )

        if ( pages.get( instanced_path ) ) {
            path = instanced_path + '/' + query + '/'
            let state = { path: path, uid: window.history.length }
            window.history.replaceState(
                state,
                null,
                window.location.origin + '/' + path,
            )
        }
        else {
            path = 'error/404/'
            let state = { path: path, uid: window.history.length }
            window.history.replaceState(
                state,
                null,
                window.location.origin + '/' + path,
            )
        }

    }

    // We have found ourselves
    status.current = path

    // Nav away!
    build_new_page( path )

}

// HANDLE internal link click
export let link_click = ( full_link, event ) => {

    if ( event ) {
        event.preventDefault()
    }

    if ( status.transitioning ) {
        return
    }

    // MANUALLY trigger a link click by passing in the path, instead of an el
    let url = new URL( full_link )
    let link = url.pathname
    if ( link.startsWith( '/' ) ) {
        link = link.slice( 1, link.length ) 
    }

    if ( status.current === link ) {
        // Same page, do nothing
    }
    else {
        // Update status tracker
        status.previous = status.current
        status.current = link

        // Update browser history
        let state = { path: link, uid: window.history.length + 1 }
        let pretty = ( link === 'home' ) ? '' : link + '/'
        window.history.pushState(
            state,
            null,
            window.location.origin + '/' + pretty )

        status.uid = window.window.history.state.uid
        status.reverse = false

        // Off to the router
        build_new_page( link )
    }
}

// HANDLE an external link click
export let external_link_click = ( el ) => {

    let link = el.dataset.external
    window.open( link, '_blank' )

}

// ROUTE both instanced and normal paths
export let build_new_page = ( _url ) => {

    status.transitioning = true

    let url = _url.endsWith( '/' )
        ? _url.slice( 0, _url.length - 1 )
        : _url
    
    // Prep url components for testing
    let url_parts = url.split( '/' )
    
    // Format path with periods instead of slashes
    let normal_path = url.replaceAll( '/', '.' )
    
    // String after final / is instance id, the rest is the path
    let query = url_parts[ url_parts.length - 1 ]
    let instanced_path = normal_path.replace( `.${query}`, '' )

    // Normal path exists as page
    if ( pages.get( normal_path ) ) {
        let page = pages.all[ normal_path ]()
        transition( page )
    }
    // Instanced path exists as a category with instances
    else if ( pages.get( instanced_path ) ) {
        let page = pages.all[ instanced_path ]()
        transition( page, query )
    }
    else {
        let page = pages.all[ 'error' ]()
        transition( page, '404' )
    }

}

// SEQUENCE page transitions
let transition = async ( page, query ) => {

    // FETCH the pages data
    await page.fetch( query )

    // POPULATE the template
    let new_el = page.build()

    // REMOVE any static content
    let static_container = document.querySelector( '.container-inner.static' )
    if ( static_container ) {
        static_container.remove()
    }

    // INSERT into the parent container
    let parent = document.querySelector( '.container' )
    parent.appendChild( new_el )

    // START the tween out
    if ( status.pages.current ) {
        await status.pages.current.tween_out( page.path, status.reverse )
    }

    // RESET the container scroll
    controller.reset_scroll()

    // START the tween in
    let prev = status.pages.current
        ? status.pages.current.path
        : false
    await page.tween_in( prev, status.reverse )

    // DESTROY the previous page
    if ( status.pages.current ) {
        status.pages.current.destroy()
    }

    // SWAP the pages in status object
    status.pages.current = page

    lazyload.update()

    links_add()

    status.transitioning = false
	
}

// - - - Add link click handlers on new content - - - //

export let links_add = ( el ) => {

    el = el ? el : document.querySelector( 'body' )

    let new_links = Array.prototype.slice.call( el.querySelectorAll( '[data-link]' ) )

    new_links.forEach( x => {
        x.addEventListener( 'click', ( e ) => {
            link_click( x.href, e )
        } )
    } )

    let new_externals = Array.prototype.slice.call( el.querySelectorAll( '[data-external]' ) )

    new_externals.forEach( x => {
        x.addEventListener( 'click', ( e ) => {
            external_link_click( x.href, e )
        } )
    } )

}
